import React, { useContext, useState, useEffect } from 'react';
type defaultState = {
  openPropertyLinksInTheSameTab: boolean;
};
const defaultExperimentState: defaultState = {
  openPropertyLinksInTheSameTab: false
};
const ExperimentContext = React.createContext(defaultExperimentState);
export const ExperimentProvider = ({
  children,
  initialState
}: {
  children: React.ReactNode;
  initialState?: defaultState;
}) => {
  const [state, setState] = useState(initialState || defaultExperimentState);
  useEffect(() => {
    const updateStateFromLocalStorage = (e?: StorageEvent) => {
      if (e && e.key !== 'openafs003') return;
      const openPropertyLinksInTheSameTab = window.localStorage.getItem('openafs003') === 'true';
      setState({
        openPropertyLinksInTheSameTab
      });
    };
    updateStateFromLocalStorage();
    window.addEventListener('storage', updateStateFromLocalStorage);
    return () => {
      window.removeEventListener('storage', updateStateFromLocalStorage);
    };
  }, []);
  return <ExperimentContext.Provider value={{
    ...state
  }} data-sentry-element="unknown" data-sentry-component="ExperimentProvider" data-sentry-source-file="experimentContext.tsx">
      {children}
    </ExperimentContext.Provider>;
};
export const useExperimentContext = () => useContext(ExperimentContext);